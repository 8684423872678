// components/GoogleAnalytics.jsx
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-F8630Y16MY"//process.env.REACT_APP_GA_TRACKING_ID; // Your GA4 tracking ID

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Initialize GA4
    if (TRACKING_ID) {
      ReactGA.initialize(TRACKING_ID, {
        gaOptions: {
          debug_mode: process.env.NODE_ENV === 'development'
        }
      });
    }
  }, []);

  useEffect(() => {
    if (TRACKING_ID) {
      // Track pageview on route change
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }
  }, [location]);

  return null;
};

export default GoogleAnalytics;

// Analytics utility functions
export const trackEvent = (eventName, params = {}) => {
  if (TRACKING_ID && process.env.NODE_ENV === 'production') {
    ReactGA.event({
      category: params.category || 'User Interaction',
      action: eventName,
      label: params.label,
      value: params.value,
      nonInteraction: params.nonInteraction || false,
      ...params
    });
  }
};

export const trackUserLogin = (userId, userRole) => {
  if (TRACKING_ID && process.env.NODE_ENV === 'production') {
    ReactGA.set({
      userId: userId,
      userRole: userRole
    });
    trackEvent('user_login', {
      category: 'User',
      label: `Role: ${userRole}`
    });
  }
};

export const trackNavigation = (fromPath, toPath) => {
  trackEvent('navigation', {
    category: 'Navigation',
    action: 'Page Change',
    label: `${fromPath} → ${toPath}`
  });
};

export const trackUserAction = (actionName, componentName, additionalParams = {}) => {
  trackEvent('user_action', {
    category: 'User Interface',
    action: actionName,
    label: componentName,
    ...additionalParams
  });
};

export const trackError = (error, componentName) => {
  trackEvent('error', {
    category: 'Error',
    action: 'Application Error',
    label: `${componentName}: ${error.message}`
  });
};

export const trackFeatureUsage = (featureName, action, params = {}) => {
  trackEvent('feature_usage', {
    category: 'Feature',
    action: action,
    label: featureName,
    ...params
  });
};

export const trackLoadTime = (componentName, loadTimeMs) => {
  trackEvent('performance', {
    category: 'Performance',
    action: 'Component Load Time',
    label: componentName,
    value: Math.round(loadTimeMs)
  });
};