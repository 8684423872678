import React, { useState, useMemo, useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import GoogleAnalytics, { 
  trackUserLogin, 
  trackEvent, 
  trackError, 
  trackLoadTime,
  trackFeatureUsage 
} from './components/GoogleAnalytics';

import 'katex/dist/katex.min.css';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { 
  CssBaseline, 
  Box, 
  CircularProgress, 
  Typography,
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import ConstructionIcon from '@mui/icons-material/Construction';

// Import components
import Header from "./components/layout/Header";
import 'katex/dist/katex.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const AdminLeaderboard = lazy(() => import('./components/AdminLeaderboard'));
const UserLeaderboard = lazy(() => import('./components/UserLeaderboard'));

// Configuration du mode construction
const CONSTRUCTION_MODE = false; // Mettre à false pour désactiver
const AUTHORIZED_USERS = ['admin']; // Roles autorisés

// Lazy-loaded components
const Login = lazy(() => import("./components/Login"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const AdminDashboard = lazy(() => import("./components/AdminDashboard"));
const LandingPage = lazy(() => import("./components/LandingPage"));
const UserPlan = lazy(() => import('./components/UserPlan'));
const AskQuestion = lazy(() => import('./components/AskQuestion'));
const LearningPathGenerator = lazy(() => import('./components/LearningPathGenerator'));
const UserCourses = lazy(() => import('./components/UserCourses'));
const Profile = lazy(() => import('./components/Profile'));
const Leaderboard = lazy(() => import('./components/UserLeaderboard'));

// Create theme
const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // Light mode colors based on DeeProf logo
          primary: {
            main: "#6A1B9A", // Deep purple from logo
            light: "#8E24AA",
            dark: "#4A148C",
            contrastText: "#ffffff",
          },
          secondary: {
            main: "#00B4B4", // Turquoise from logo
            light: "#00E5E5",
            dark: "#008080",
            contrastText: "#ffffff",
          },
          background: {
            default: "#F8F9FC",
            paper: "#ffffff",
            gradient: "linear-gradient(135deg, #6A1B9A 0%, #00B4B4 100%)",
          },
          text: {
            primary: "#1A1A3A",
            secondary: "#4A4A6A",
            disabled: "#9898A6",
          },
          divider: "rgba(106, 27, 154, 0.08)",
        }
      : {
          // Dark mode colors based on DeeProf logo
          primary: {
            main: "#9C27B0", // Brighter purple for dark mode
            light: "#BA68C8",
            dark: "#7B1FA2",
            contrastText: "#ffffff",
          },
          secondary: {
            main: "#00E5E5", // Brighter turquoise for dark mode
            light: "#40FFFF",
            dark: "#00B4B4",
            contrastText: "#000000",
          },
          background: {
            default: "#0A0A1E", // Deep navy background
            paper: "#1A1A3A",
            gradient: "linear-gradient(135deg, #4A148C 0%, #008080 100%)",
          },
          text: {
            primary: "#ffffff",
            secondary: "#B0B0C0",
            disabled: "#6A6A8A",
          },
          divider: "rgba(255, 255, 255, 0.08)",
        }),
  },
  typography: {
    fontFamily: '"Inter var", "Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    h1: {
      fontWeight: 800,
      fontSize: "3.5rem",
      background: mode === "light" 
        ? "linear-gradient(135deg, #6A1B9A, #00B4B4)"
        : "linear-gradient(135deg, #9C27B0, #00E5E5)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      letterSpacing: "-0.02em",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
          padding: "10px 24px",
          fontSize: "0.9375rem",
          fontWeight: 600,
          transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: mode === "light"
              ? "0 6px 20px rgba(106, 27, 154, 0.2)"
              : "0 6px 20px rgba(156, 39, 176, 0.2)",
          },
        },
        containedPrimary: {
          background: mode === "light"
            ? "linear-gradient(135deg, #6A1B9A 0%, #8E24AA 100%)"
            : "linear-gradient(135deg, #7B1FA2 0%, #9C27B0 100%)",
          "&:hover": {
            background: mode === "light"
              ? "linear-gradient(135deg, #4A148C 0%, #6A1B9A 100%)"
              : "linear-gradient(135deg, #6A1B9A 0%, #7B1FA2 100%)",
          },
        },
        containedSecondary: {
          background: mode === "light"
            ? "linear-gradient(135deg, #00B4B4 0%, #00E5E5 100%)"
            : "linear-gradient(135deg, #008080 0%, #00B4B4 100%)",
          "&:hover": {
            background: mode === "light"
              ? "linear-gradient(135deg, #008080 0%, #00B4B4 100%)"
              : "linear-gradient(135deg, #006666 0%, #008080 100%)",
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          boxShadow: mode === "light"
            ? "0 4px 20px rgba(106, 27, 154, 0.08)"
            : "0 4px 20px rgba(0, 0, 0, 0.2)",
          transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
          "&:hover": {
            transform: "translateY(-4px)",
            boxShadow: mode === "light"
              ? "0 12px 40px rgba(106, 27, 154, 0.12)"
              : "0 12px 40px rgba(0, 0, 0, 0.3)",
          },
          background: mode === "light" 
            ? "#ffffff" 
            : "rgba(26, 26, 58, 0.8)",
          backdropFilter: "blur(12px)",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: mode === "light"
            ? "rgba(255, 255, 255, 0.9)"
            : "rgba(10, 10, 30, 0.9)",
          backdropFilter: "blur(12px)",
          boxShadow: "none",
          borderBottom: `1px solid ${mode === "light"
            ? "rgba(106, 27, 154, 0.08)"
            : "rgba(255, 255, 255, 0.08)"}`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 8,
          background: mode === "light"
            ? "linear-gradient(135deg, #6A1B9A 0%, #00B4B4 100%)"
            : "linear-gradient(135deg, #9C27B0 0%, #00E5E5 100%)",
          color: "#ffffff",
          fontSize: "0.875rem",
          padding: "8px 16px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    // Nouvelles configurations pour les composants de formulaire
    MuiSelect: {
      styleOverrides: {
        root: {
          color: mode === 'light' ? '#1A1A3A' : '#ffffff',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: mode === 'light' ? '#6A1B9A' : '#9C27B0',
          },
        },
        icon: {
          color: mode === 'light' ? 'rgba(26, 26, 58, 0.7)' : 'rgba(255, 255, 255, 0.7)',
        },
      },
      defaultProps: {
        MenuProps: {
          PaperProps: {
            sx: {
              bgcolor: mode === 'light' ? '#ffffff' : '#1A1A3A',
              backgroundImage: 'none',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
              '& .MuiMenuItem-root': {
                color: mode === 'light' ? '#1A1A3A' : '#ffffff',
                padding: '12px 16px',
                '&:hover': {
                  bgcolor: mode === 'light' 
                    ? 'rgba(106, 27, 154, 0.08)' 
                    : 'rgba(156, 39, 176, 0.08)',
                },
                '&.Mui-selected': {
                  bgcolor: mode === 'light' 
                    ? 'rgba(106, 27, 154, 0.12)' 
                    : 'rgba(156, 39, 176, 0.12)',
                  '&:hover': {
                    bgcolor: mode === 'light' 
                      ? 'rgba(106, 27, 154, 0.16)' 
                      : 'rgba(156, 39, 176, 0.16)',
                  },
                },
              },
            },
          },
          sx: {
            mt: 1,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: mode === 'light' 
            ? 'rgba(255, 255, 255, 0.9)' 
            : 'rgba(26, 26, 58, 0.8)',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: mode === 'light' 
              ? 'rgba(26, 26, 58, 0.2)' 
              : 'rgba(255, 255, 255, 0.2)',
            transition: 'border-color 0.2s ease-in-out',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: mode === 'light' 
              ? 'rgba(106, 27, 154, 0.3)' 
              : 'rgba(156, 39, 176, 0.3)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: mode === 'light' ? '#6A1B9A' : '#9C27B0',
            borderWidth: 2,
          },
          '&.Mui-disabled': {
            backgroundColor: mode === 'light' 
              ? 'rgba(0, 0, 0, 0.05)' 
              : 'rgba(255, 255, 255, 0.05)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: mode === 'light' 
            ? 'rgba(26, 26, 58, 0.7)' 
            : 'rgba(255, 255, 255, 0.7)',
          '&.Mui-focused': {
            color: mode === 'light' ? '#6A1B9A' : '#9C27B0',
          },
          '&.Mui-error': {
            color: '#f44336',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: mode === 'light' 
            ? 'rgba(26, 26, 58, 0.6)' 
            : 'rgba(255, 255, 255, 0.6)',
          marginTop: '8px',
          fontSize: '0.75rem',
          '&.Mui-error': {
            color: '#f44336',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: '40px',
          padding: '8px 16px',
          '&:hover': {
            backgroundColor: mode === 'light'
              ? 'rgba(106, 27, 154, 0.08)'
              : 'rgba(156, 39, 176, 0.08)',
          },
          '&.Mui-selected': {
            backgroundColor: mode === 'light'
              ? 'rgba(106, 27, 154, 0.12)'
              : 'rgba(156, 39, 176, 0.12)',
            '&:hover': {
              backgroundColor: mode === 'light'
                ? 'rgba(106, 27, 154, 0.16)'
                : 'rgba(156, 39, 176, 0.16)',
            },
          },
        },
      },
    },
  },
});
// Composant pour le message de restriction d'accès
const ConstructionRestriction = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      bgcolor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999,
      p: 3,
      textAlign: 'center'
    }}
  >
    <Box
      sx={{
        maxWidth: 400,
        p: 4,
        borderRadius: 2,
        bgcolor: 'background.paper',
        boxShadow: 3
      }}
    >
      <ConstructionIcon 
        sx={{ 
          fontSize: 48, 
          mb: 2, 
          color: 'warning.main',
          opacity: 0.8
        }} 
      />
      <Typography 
        variant="h5" 
        component="h1" 
        gutterBottom
        sx={{ fontWeight: 500 }}
      >
        Maintenance en cours
      </Typography>
      <Typography 
        variant="body2" 
        color="text.secondary"
        sx={{ mb: 2 }}
      >
        Notre équipe travaille actuellement sur des améliorations.
        <br />
        Le site sera de nouveau accessible très prochainement.
      </Typography>
    </Box>
  </Box>
);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mode, setMode] = useState("dark");
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [debugInfo, setDebugInfo] = useState({
    components: [],
    renderCount: 0,
    errors: [],
    loadTime: {},
  });

  // Vérification de l'accès au mode construction
  const checkConstructionAccess = () => {
    if (!CONSTRUCTION_MODE) return true;
    return user && AUTHORIZED_USERS.includes(user.role);
  };

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((e) => {
        console.error(`Error attempting to enable full-screen mode: ${e.message}`);
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  // Enhanced checkAuth with analytics
  const checkAuth = async () => {
    const startTime = performance.now();
    const token = localStorage.getItem("token");
    const storedUserInfo = localStorage.getItem("userInfo");
  
    if (token && storedUserInfo) {
      try {
        const userInfo = JSON.parse(storedUserInfo);
        setIsAuthenticated(true);
        setUser(userInfo);
        
        // Track successful authentication
        trackUserLogin(userInfo.id, userInfo.role);
        trackLoadTime('Authentication', performance.now() - startTime);
        
        if (CONSTRUCTION_MODE) {
          setDebugInfo(prev => ({
            ...prev,
            loadTime: {
              ...prev.loadTime,
              auth: new Date().toISOString()
            }
          }));
        }
      } catch (error) {
        console.error("Error parsing stored user info:", error);
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        setIsAuthenticated(false);
        setUser(null);
        
        // Track authentication error
        trackError(error, 'Authentication');
        
        if (CONSTRUCTION_MODE) {
          setDebugInfo(prev => ({
            ...prev,
            errors: [...prev.errors, error.message]
          }));
        }
      }
    } else {
      setIsAuthenticated(false);
      setUser(null);
    }
    setIsLoading(false);
  };


  useEffect(() => {
    checkAuth();

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    const handleAppInstalled = () => {
      if (CONSTRUCTION_MODE) {
        setDebugInfo(prev => ({
          ...prev,
          loadTime: {
            ...prev.loadTime,
            pwaInstall: new Date().toISOString()
          }
        }));
      }
    };

    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', handleAppInstalled);
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleAppInstalled);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const isAdmin = () => user && user.role === 'admin';

  if (isLoading) {
    return <CircularProgress />;
  }

  const MainLayout = ({ children }) => (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {CONSTRUCTION_MODE && !checkConstructionAccess() ? (
        <ConstructionRestriction />
      ) : (
        <>
          {/* Header toujours présent mais avec apparence modifiée en mode construction */}
          {isAuthenticated && (
            <Box sx={{
              opacity: CONSTRUCTION_MODE ? 0.7 : 1,
              filter: CONSTRUCTION_MODE ? 'grayscale(30%)' : 'none',
              transition: 'opacity 0.3s, filter 0.3s'
            }}>
              <Header  
                user={user}
                setIsAuthenticated={setIsAuthenticated}
                colorMode={colorMode}
                mode={mode}
                isFullscreen={isFullscreen}
                toggleFullscreen={toggleFullscreen}
                onInstallClick={() => deferredPrompt?.prompt()}
                showInstallPrompt={!!deferredPrompt}
              />
            </Box>
          )}
          <Box 
            component="main" 
            sx={{ 
              flexGrow: 1, 
              display: "flex", 
              flexDirection: "column", 
              py: 3,
              position: 'relative'
            }}
          >
            <Suspense fallback={<CircularProgress />}>
              {children}
            </Suspense>
          </Box>
        </>
      )}
    </Box>
  );

  const MinimalLayout = ({ children }) => (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      {CONSTRUCTION_MODE && !checkConstructionAccess() ? (
        <ConstructionRestriction />
      ) : (
        <Suspense fallback={<CircularProgress />}>
          {children}
        </Suspense>
      )}
    </Box>
  );

  // Indicateur discret du mode construction
  const ConstructionIndicator = () => {
    if (!CONSTRUCTION_MODE || !checkConstructionAccess()) return null;
    
    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 9999,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          bgcolor: 'rgba(0, 0, 0, 0.6)',
          color: '#fff',
          padding: '4px 8px',
          borderRadius: 1,
          fontSize: '0.75rem',
          backdropFilter: 'blur(4px)',
          cursor: 'default',
          userSelect: 'none',
          transition: 'opacity 0.3s ease',
          opacity: 0.7,
          '&:hover': {
            opacity: 1
          }
        }}
      >
        <BuildIcon sx={{ fontSize: '1rem' }} />
        <span>Dev</span>
      </Box>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ position: 'relative', minHeight: '100vh' }}>

      <Router>
        <Routes>
          <Route
            path="/"
            element={
              isAuthenticated ? (
                <Navigate to={isAdmin() ? "/admin-dashboard" : "/user-dashboard"} />
              ) : (
                <MinimalLayout>
                  <LandingPage setIsAuthenticated={setIsAuthenticated} setUser={setUser} />
                </MinimalLayout>
              )
            }
          />
          
          <Route path="/leaderboard" element={<MainLayout><Leaderboard /></MainLayout>} />
          {/* Admin Leaderboard Route */}
          <Route
            path="/admin/leaderboard"
            element={
              isAuthenticated && isAdmin() ? (
                <MainLayout>
                  <AdminLeaderboard />
                </MainLayout>
              ) : isAuthenticated ? (
                <Navigate to="/leaderboard" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />

          {/* User Leaderboard Route */}
          <Route
            path="/leaderboard"
            element={
              isAuthenticated ? (
                <MainLayout>
                  {isAdmin() ? <Navigate to="/admin/leaderboard" /> : <UserLeaderboard />}
                </MainLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to="/user-dashboard" />
              ) : (
                <MainLayout>
                  <Login setIsAuthenticated={setIsAuthenticated} setUser={setUser} />
                </MainLayout>
              )
            }
          />
          <Route
            path="/user-dashboard"
            element={
              isAuthenticated ? (
                <MainLayout>
                  <Dashboard colorMode={colorMode} />
                </MainLayout>
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/admin-dashboard"
            element={
              isAuthenticated && isAdmin() ? (
                <MainLayout>
                  <AdminDashboard colorMode={colorMode} />
                </MainLayout>
              ) : isAuthenticated ? (
                <Navigate to="/user-dashboard" />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/user-plan"
            element={
              isAuthenticated ? (
                <MainLayout>
                  <UserPlan />
                </MainLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/ask-question"
            element={
              isAuthenticated ? (
                <MinimalLayout>
                  <AskQuestion />
                </MinimalLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/learning-path"
            element={
              isAuthenticated ? (
                <MainLayout>
                  <LearningPathGenerator />
                </MainLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/courses"
            element={
              isAuthenticated ? (
                <MainLayout>
                  <UserCourses user={user} colorMode={colorMode} />
                </MainLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/profile"
            element={
              isAuthenticated ? (
                <MainLayout>
                  <Profile />
                </MainLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
        <ConstructionIndicator />
      </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;