import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  Box,
  Avatar,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  useMediaQuery,
  Tooltip,
  Badge,
  CircularProgress,
  Typography,
  styled,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  AccountCircle,
  Brightness4,
  Brightness7,
  ExitToApp,
  Dashboard,
  Person,
  MenuBook,
  BarChart,
  Menu as MenuIcon,
  Token,
  QuestionAnswer,
  School,
  Refresh,
  Fullscreen,
  FullscreenExit,
  GetApp,
} from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";

import config from '../../config';
import Logo from './../Logo';  // Adjust path as needed

const API_URL = config.API_BASE_URL;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 0.5),
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

function Header({ user, setIsAuthenticated, colorMode, mode, isFullscreen, toggleFullscreen }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tokenInfo, setTokenInfo] = useState({ balance: 0, used: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isIOS, setIsIOS] = useState(false);
  const [showInstallDialog, setShowInstallDialog] = useState(false);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    setIsAuthenticated(false);
    navigate("/");
  };

  const fetchTokenInfo = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_URL}/user/token-info`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      });
      setTokenInfo({
        balance: response.data.token_balance,
        used: response.data.tokens_used
      });
    } catch (error) {
      console.error("Failed to fetch token info:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(`${API_URL}/user/profile`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      });
      if (response.data.profile_image) {
        setProfileImage(getImageUrl(response.data.profile_image));
      }
    } catch (error) {
      console.error("Failed to fetch user profile:", error);
    }
  };

  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    if (!imagePath.startsWith('static/profile_images/')) {
      imagePath = `static/profile_images/${imagePath}`;
    }
    return `${API_URL}/${imagePath}`;
  };

  useEffect(() => {
    if (user) {
      fetchTokenInfo();
      fetchUserProfile();
    }

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Check if the device is iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOS);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, [user]);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      setDeferredPrompt(null);
    } else if (isIOS) {
      setShowInstallDialog(true);
    }
  };

  const isAdmin = user && user.role === 'admin';

  const menuItems = [
    { text: "Dashboard", icon: <Dashboard />, path: isAdmin ? "/admin-dashboard" : "/user-dashboard" },
    { text: "My Plan", icon: <BarChart />, path: "/user-plan" },
    { text: "Ask Question", icon: <QuestionAnswer />, path: "/ask-question" },
    //{ text: "Learning Path", icon: <School />, path: "/learning-path" },
    // { text: "Courses", icon: <MenuBook />, path: "/courses" },
    // { text: "Profile", icon: <Person />, path: "/profile" },
  ];

  const drawer = (
    <Box sx={{ width: 250 }}>
      <List>
        {menuItems.map((item) => (
          <ListItem button key={item.text} component={Link} to={item.path}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  const TokenDisplay = () => (
    <Box sx={{
      padding: theme.spacing(2),
      width: '100%',
      maxWidth: '300px',
    }}>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        Token Usage
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <LinearProgress
          variant="determinate"
          value={(tokenInfo.used / (tokenInfo.used + tokenInfo.balance)) * 100}
          sx={{ flexGrow: 1, mr: 1, height: 10, borderRadius: 5 }}
        />
        <Typography variant="caption">
          {tokenInfo.balance} left
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="body2">
          {tokenInfo.used} used / {tokenInfo.used + tokenInfo.balance} total
        </Typography>
        <Tooltip title="Refresh token info">
          <IconButton
            onClick={fetchTokenInfo}
            disabled={isLoading}
            size="small"
          >
            {isLoading ? <CircularProgress size={24} /> : <Refresh />}
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );

  const IOSInstallDialog = () => (
    <Dialog open={showInstallDialog} onClose={() => setShowInstallDialog(false)}>
      <DialogTitle>Install DeeProf App</DialogTitle>
      <DialogContent>
        <Typography>
          To install the DeeProf app on your iOS device:
          <ol>
            <li>Tap the Share button in Safari.</li>
            <li>Scroll down and tap "Add to Home Screen".</li>
            <li>Tap "Add" in the top-right corner.</li>
          </ol>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowInstallDialog(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <StyledAppBar position="static">
      <Toolbar sx={{ 
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          flexGrow: 1,
        }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Box 
            component={Link} 
            to={isAdmin ? "/admin-dashboard" : "/user-dashboard"}
            sx={{ 
              textDecoration: 'none', 
              color: 'inherit',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Logo variant={isMobile ? "small" : "default"} />
          </Box>
        </Box>
        {!isMobile && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {menuItems.map((item) => (
              <StyledButton
                key={item.text}
                color="inherit"
                startIcon={item.icon}
                component={Link}
                to={item.path}
              >
                {item.text}
              </StyledButton>
            ))}
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {(deferredPrompt || isIOS) && (
            <Tooltip title="Install App">
              <IconButton color="inherit" onClick={handleInstallClick}>
                <GetApp />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={mode === "dark" ? "Switch to Light Mode" : "Switch to Dark Mode"}>
            <IconButton onClick={colorMode.toggleColorMode} color="inherit">
              {mode === "dark" ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
          </Tooltip>
          <Tooltip title={isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}>
            <IconButton onClick={toggleFullscreen} color="inherit">
              {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>
          </Tooltip>
          <Tooltip title={user?.username || "Account"}>
            <IconButton onClick={handleMenu} color="inherit" sx={{ ml: 1 }}>
              <Badge color="secondary" variant="dot" invisible={!isAdmin}>
                <Avatar 
                  alt={user?.username} 
                  src={profileImage}
                  sx={{ width: 40, height: 40 }}
                >
                  {!profileImage && (user?.username ? user.username[0].toUpperCase() : <AccountCircle />)}
                </Avatar>
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {user && <TokenDisplay />}
        <MenuItem onClick={() => { handleClose(); navigate("/profile"); }}>
          <ListItemIcon><Person fontSize="small" /></ListItemIcon>
          <ListItemText primary="Profile" secondary={user?.email} />
        </MenuItem>
        <MenuItem onClick={() => { handleClose(); navigate("/user-plan"); }}>
          <ListItemIcon><BarChart fontSize="small" /></ListItemIcon>
          <ListItemText primary="My Plan" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon><ExitToApp fontSize="small" /></ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
      >
        {drawer}
      </Drawer>
      <IOSInstallDialog />
    </StyledAppBar>
  );
}

export default Header;