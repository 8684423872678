// src/config.js
const config = {
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL || (
    process.env.NODE_ENV === 'development'
      ? 'http://195.35.28.150:8000/api'
      : 'https://deeprof.com/api'
  ),
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY || 'pk_test_51Q1PGxP63h0os66P6rw0qF8kW6Aw28AGx0dA0nAWnyQ5HziNdCUSqjDrJKaXz1Am4PIS5IKbDbyBa5Fys6cawhpn00HXut8qjb',
  
  // Ajoutez d'autres configurations utiles
  API_TIMEOUT: 15000, // 15 secondes
  MAX_RETRIES: 3,
  VERSION: process.env.REACT_APP_VERSION || '1.0.0',
};

// Validation des configurations critiques
const validateConfig = () => {
  const requiredConfigs = ['API_BASE_URL', 'STRIPE_PUBLIC_KEY'];
  requiredConfigs.forEach(configKey => {
    if (!config[configKey]) {
      console.error(`Missing required configuration: ${configKey}`);
    }
  });
};

if (process.env.NODE_ENV === 'development') {
  validateConfig();
}

export default config;