import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';

const Logo = ({ variant = "default" }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const sizeMap = {
    small: { height: 52, width: 'auto' },
    default: { height: 60, width: 'auto' },
    large: { height: 70, width: 'auto' }
  };

  const size = isMobile ? sizeMap.small : sizeMap[variant];

  return (
    <Box 
      component="img"
      src="/DeeProf-logo.png"
      alt="DeeProf"
      sx={{
        ...size,
        mr: 1,
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.05)',
        },
        display: 'block'
      }}
    />
  );
};

export default Logo;